<template>

  <div class="mt-12 flex justify-center items-center w-full h-full">

    <div class="m-4 z-10 flex flex-col rounded-xl il-shadow bg-white">

      <div class="text-xl w-300px p-4 bg-il-purple text-white text-center uppercase rounded-t-xl">
        Login
      </div>

      <div class="p-4 text-sm">
        <div class="text-gray-500">
          Email
        </div>
        <div>
          <input
          @keyup.enter="login"
          v-model="email"
          type="text" class="text-lg text-il-purple w-full py-2 border-b border-gray-300 focus:outline-none focus:border-il-purple">
        </div>
      </div>

      <div class="p-4 text-sm">
        <div class="text-gray-500">
          Password
        </div>
        <div>
          <input
          @keyup.enter="login"
          v-model="password"
          type="password" class="text-lg text-il-purple w-full py-2 border-b border-gray-300 focus:outline-none focus:border-il-purple">
        </div>
      </div>

      <div class="flex justify-center items-center pb-4">
        <div
          class="transition-all ease-in-out duration-200 bg-il-purple text-white py-2 uppercase rounded-xl text-center"
          :class="loginLoading || loginSuccess ? 'w-100px' : 'cursor-pointer w-200px'"
          @click="login"
          >
          <span v-if="loginSuccess">
            <i class="fas fa-check"></i>
          </span>
          <span v-else-if="loginLoading">
            <i class="fas fa-circle-notch fa-spin"></i>
          </span>
          <span v-else>
            Submit
          </span>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import authRequests from '../../helpers/authRequests';

  export default {
    name: 'home',
    mounted() {

    },
    data() {
      return {
        email: null,
        password: null,
        loginLoading: false,
        loginSuccess: false
      }
    },
    methods: {
      login() {
        if(!this.loginLoading) {
          this.loginLoading = true;
          // Log the user in.
          authRequests.login(this.email, this.password)
          .then((response) => {
            this.loginUserX(response.data.user);
            this.setDefaultPaymentX(response.data.default_payment);
            this.setIsOnTrialX(response.data.is_on_trial);
            this.setIsSubscribedX(response.data.is_subscribed);
            this.setSubscribedBeforeX(response.data.subscribed_before);
            this.setNextPaymentDateX(response.data.next_payment_date);
            this.loginLoading = false;
            this.loginSuccess = true;
            setTimeout(() => {
              this.$router.push({ name: 'profile' })
            }, 1000);
          }).catch(error => {
            // Login failed
            this.loginLoading = false;
            this.$toast.error(error.response.data.message, {
              position: "bottom"
            });
          });
        }
      },
      ...mapMutations([
        'loginUserX',
        'setDefaultPaymentX',
        'setIsOnTrialX',
        'setIsSubscribedX',
        'setSubscribedBeforeX',
        'setNextPaymentDateX'
      ]),
    }
  }
</script>

<style scoped>
</style>